<template>
  <div class="login">
    <Header></Header>
    <div class="main">
      <div class="center-wrapper">
        <div class="wrap">
          <label>登录账号</label>
          <el-input
            type="text"
            placeholder="请输入内容"
            prefix-icon="el-icon-user-solid"
            v-model="num"
            @change="numState"
            clearable
          >
          </el-input>
        </div>
        <div class="wrap">
          <label>登录密码</label>
          <el-input
            type="text"
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            v-model="pwd"
            @change="pwdState"
            show-password
            clearable
          >
          </el-input>
        </div>
        <button type="button" @click="loginUser">立即登录</button>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { login } from "@/api/login";

export default {
  name: "Login",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      num: "",
      pwd: ""
    };
  },
  methods: {
    loadData() {},
    numState(){
      if (!this.num) {
        this.$message.error("账号不能为空");
      }
    },
    pwdState(){
      if (!this.pwd) {
          this.$message.error("密码不能为空");
      }
    },
    loginUser() {
      if (!this.num) {
        this.$message.error("账号不能为空");
        return
      }
      if (!this.pwd) {
          this.$message.error("密码不能为空");
        return
      }
      let formData = new URLSearchParams();
      formData.append("username", this.num);
      formData.append("password", this.pwd);
      // var formData = {
      //   username: this.num,
      //   password: this.pwd,
      // };
      // formData = JSON.parse(formData);
      login(formData).then((res) => {
        if (res.code == 0) {
          this.$store.commit("setUserName", this.num);
          this.$store.commit("setPassword", this.pwd);
          this.$store.commit("setUserId", res.userId);
          this.$store.commit("setToken", res.token);
          setTimeout(()=>{
            this.$router.push({ path: "/" });
          },50)
        }else{
          this.$message.error(res.msg);
        }
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  height: calc(100vh - 85px);
  background: url("../assets/home/loginBg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.center-wrapper {
  width: 446px;
  height: 386px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 1px 5px 7px 0px rgba(0, 0, 0, 0.56);
  border-radius: 4px;
  padding: 44px 42px 41px 35px;
  box-sizing: border-box;
}
.center-wrapper label {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: 14px;
  font-weight: 400;
  color: #666f74;
}
.center-wrapper button {
  width: 369px;
  height: 54px;
  background: #3a91fb;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 54px;
  border: none;
  cursor: pointer;
  margin-top: 50px;
}
.wrap:first-child {
  margin-bottom: 32px;
}
.wrap >>> .el-input__inner {
  height: 42px;
}
</style>
